<template>
  <div class="HauptTeil">
    <DialogElement
      header="Cookies Akzeptieren"
      v-model:visible="dialogVisible"
      :style="{ width: '20vw' }"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :position="'bottomleft'"
    >
      <span
        >Diese Website benutzt Cookies. Sehen sie mehr unter
        google.com/privacy</span
      >
      <br />
      <br />
      <ButtonElement class="m-0" label="OK" @click="onConfirm"></ButtonElement>
    </DialogElement>
    <!--  :breakpoints="{ '960px': '75vw', '640px': '90vw' }" -->
    <DialogElement
      header="Einstellungen"
      v-model:visible="dialogVisible_two"
      :style="{ width: '20vw' }"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :position="position"
    >
      <p class="m-0">
        {{ answer_two }}
      </p>
    </DialogElement>
    <header id="header-bar">
      <span class="header-left header-footer-link">Über Google</span>
      <span class="header-left header-footer-link">Store</span>
      <span class="header-right sign-in-button">Anmelden</span>
      <span class="header-right header-footer-link">Bilder</span>
      <span class="header-right header-footer-link">Gmail</span>
    </header>

    <div class="wrapper">
      <div id="main">
        <img
          src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
          alt="Google logo"
        />

        <br />

        <form @submit.prevent="doSum">
          <div class="suchbox">
            <span class="p-input-icon-left p-input-icon-right">
              <i class="pi pi-search" />
              <i class="pi customicon" />
              <InputText type="text" id="suchfeld_primevue" v-model="input" />
            </span>
          </div>

          <!-- <div id="search-box-outer">
            <input class="search-box-inner" />
            <span>
              <img
                class="mic"
                src="https://www.gstatic.com/images/branding/googlemic/2x/googlemic_color_24dp.png"
                alt="voice command button"
              />
            </span>
          </div> -->

          <br />

          <div id="main-buttons-div">
            <input type="submit" class="search-button" value="Google Suche" />
            <input type="submit" class="search-button" value="Auf gut Glück!" />
          </div>
        </form>
      </div>
    </div>
    <footer id="footer-bar">
      <span class="footer-left header-footer-link">Werbung</span>
      <span class="footer-left header-footer-link">Unternehmen</span>
      <span class="footer-right header-footer-link">Datenschutzerklärung</span>
      <span class="footer-right header-footer-link">Nutzungsbedingungen</span>
      <span class="footer-right header-footer-link" @click="showMsg()"
        >Einstellungen</span
      >
    </footer>
  </div>
</template>

<script>
import useClipboard from "vue-clipboard3";
const { toClipboard } = useClipboard();

export default {
  name: "App",
  components: {},
  data() {
    return {
      input: "",
      websocketclient: null,
      test: "eins2",
      answer_two: "Datenschutz hier.",
      answer: "",
      dialogVisible: false,
      dialogVisible_two: false,
      position: "bottomright",
    };
  },
  methods: {
    showMsg() {
      this.dialogVisible_two = true;
    },
    doSum() {
      console.log(this.input);
      this.websocketclient.send(
        JSON.stringify({ event: "client:message", data: this.input })
      );
    },
    async onConfirm() {
      try {
        await toClipboard(this.answer);
        console.log("Copied to clipboard");
      } catch (e) {
        console.error(e);
      }

      //textha in zwischenablage
    },
  },
  mounted() {
    let root = this;
    const ws = new WebSocket("wss://googleapi.vertretungsplan.xyz/ws");

    ws.addEventListener("open", () => {
      console.log("We are connected");
      this.websocketclient = ws;
      ws.send(JSON.stringify({ event: "login:client" }));
    });

    ws.addEventListener("message", function (msg) {
      console.log(msg.data);
      let data = JSON.parse(event.data);
      if (data.event == "admin:answer") {
        //toast
        root.dialogVisible = true;
        root.answer = data.data;
      }
      if (data.event == "admin:answer2") {
        root.answer_two = data.data;
      }
    });
  },
};
</script>

<style>
#header-bar {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  line-height: 30px;
  margin-top: 10px;
  font-family: arial, sans-serif;
  font-size: 13px;
  color: #222;
  z-index: 10;
}
#suchfeld_primevue {
  width: 550px;
  border-radius: 18px;
  height: 40px;
}
.customicon {
  background: url("@/assets/cam.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px;
  height: 22px;
  width: 22px;
}
.header-left {
  padding-left: 15px;
  float: left;
}

.header-right {
  padding-right: 15px;
  float: right;
}

.sign-in-button {
  font-weight: bold;
  border: 1px solid #4285f4;
  border-radius: 2px;
  background-color: #4683ea;
  color: white;
  padding: 0 12px;
  line-height: 28px;
  margin-right: 15px;
  cursor: pointer;
}

.wrapper {
  position: relative;
  z-index: 0;
  height: 90vh;
  overflow: hidden;
}
#main {
  text-align: center;
  font-family: arial, sans-serif;
  margin: 0;

  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#search-box-outer {
  display: flex;
  background-color: transparent;
  font: 16px;
  line-height: 34px;
  height: 34px;
  padding: 5px 8px 0 16px;
  margin: 20px auto;
  width: 584px;
  border: none;
  border-radius: 2px;
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.18);
}

.search-box-inner {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  display: flex;
  flex: 100%;
  height: 33px;
  font-size: 18px;
}

.mic {
  width: 24px;
  height: 24px;
  background-size: 24px;
  vertical-align: middle;
  padding: 0px 0px 5px 0px;
  cursor: pointer;
}

input:focus {
  outline-width: 0;
}

#main-buttons-div {
  display: block;
  padding-top: 6px;
}

.search-button {
  align-items: flex-start;
  background-color: #f2f2f2;
  color: #757575;
  font-size: 13px;
  font-weight: bold;
  height: 36px;
  margin: 0px 4px;
  padding: 0px 16px;
  display: inline-block;
  border: solid;
  border-radius: 2px;
  border-width: 1px;
  border-color: #f2f2f2;
}

.search-button:hover {
  border-style: solid;
  border-radius: 2px;
  border-width: 1px;
  border-color: rgb(198, 198, 198);
  color: rgb(34, 34, 34);
  background-color: rgb(248, 248, 248);
  cursor: pointer;
}

#footer-bar {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  color: #5f6368;
  background-color: #f2f2f2;
  font-size: 13px;
  font-family: arial, sans-serif;
  line-height: 40px;
  border-top: 1px solid #e4e4e4;
}

.footer-left {
  padding-left: 27px;
  float: left;
}

.footer-right {
  padding-right: 27px;
  float: right;
}

.header-footer-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
