import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
const app = createApp(App).use(router);
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import InputText from "primevue/inputtext";
import "primeicons/primeicons.css";
import router from "./router";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
app.use(PrimeVue);
app.use(ToastService);
app.component("InputText", InputText);
app.component("DialogElement", Dialog);
app.component("ToastElement", Toast);
app.component("ButtonElement", Button);
app.mount("#app");
